import guards from './guards';
const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

const MediaList = () => import('../views/Admin/Media/MediaListing');
const MediaDetails = () => import('../views/Admin/Media/MediaDetails');
const DashBoard = () => import('../views/DashBoard');
const ChangePassword = () => import('../views/Auth/ChangePassword');
const Logout = () => import('../views/Auth/Logout');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/app/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        {
            path      : '/app/',
            name      : 'DashBoard',
            component : DashBoard
        },
        {
            path      : '/app/logout/',
            name      : 'Logout',
            component : Logout
        },
        {
            path      : '/app/change-password/',
            name      : 'ChangePassword',
            component : ChangePassword
        },
        {
            path      : '/media/',
            name      : 'MediaList',
            component : MediaList
        },
        {
            path      : '/media/:id/details/',
            name      : 'MediaDetails',
            component : MediaDetails
        }
    ]
};
