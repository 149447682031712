const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/';
} else {
    baseUrl = '/';
}

export default {
    auth : {
        login          : baseUrl + 'api/auth/login/',
        logout         : baseUrl + 'api/auth/logout/',
        ChangePassword : baseUrl + 'api/auth/set-password',
        status         : baseUrl + 'api/auth/status/'
    },
    admin : {
        media : {
            add     : baseUrl + 'api/media/create/',
            update  : baseUrl + 'api/media/update/',
            list    : baseUrl + 'api/media/list/',
            delete  : baseUrl + 'api/media/delete/',
            details : baseUrl + 'api/media/details/'
        }
    }
};
